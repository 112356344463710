// Form styles
form.default {
	overflow: hidden;
	width: 100%;

	fieldset {
		border: none;
		margin: 0;
	}

	label,
	input,
	textarea,
	select,
	button,
	[type="submit"] {
		// min-height: 3.5rem;
	}

	legend {
		@extend .h2;
	}

	label {
		color: inherit;
		display: block;
		padding: 1rem 0;
		position: relative;

		small {
			color: $alert;
			display: inline-block;
			line-height: 0.5rem;
			// position: absolute;
		}

		// &[for="Datenschutz"] {
		// 	@extend .marginBottom;
		// 	float: left;
		// 	width: calc(100% - #{rem(30px)});
		// }
	}

	[type="text"],
	[type="tel"],
	[type="email"],
	[type="file"],
	input,
	textarea,
	select {
		@extend %animatedTransform;
		background: $light;
		border: $baseBorder;
		color: $dark;
		display: block;
		font-family: $mainFont;
		font-size: rem($baseFontSize);
		line-height: rem($baseLineHeight);
		margin: 0 0 0.5rem 0;
		padding: 0.8rem;
		position: relative;
		resize: none;
		width: 100%;
		border-radius: 0;

		&:focus {
			background: darken($light, 2%);
			outline: none;
		}
	}

	textarea {
		/* fake line-height */
		min-height: 7.5rem;
		padding-top: 0.9rem;

		$inputgroup-count: 3;

		&[name="Nachricht"] {
			min-height: 7.5rem * $inputgroup-count - 4rem;
		}
	}

	[type="checkbox"] {
		display: inline-block;
		// float: right;
		margin: 0 rem(15px) 0.5rem 0;
		padding: 0;
		width: rem(15px);
		height: rem(15px);

		body.iexplore & {
			border: none;

			&:focus {
				background: none;
			}
		}
	}

	button,
	[type="submit"] {
		@extend .btn;
		margin: 0;
		width: 100%;
	}
}

/*=VALIDATION */

.specialfield {
	display: none !important;
	visibility: hidden !important;
}

#newsletter-info {
	background: $alert;
	color: $light;
	font-weight: bold;
	margin-bottom: $baseLineHeight;
	padding: $baseGap;

	*:last-of-type {
		margin-bottom: 0;
	}
}

img[name="vimg"] + input[name="imgverify"] {
	float: right;
}

// Form
form.transform {
	// Set Form relative
	position: relative;

	input::placeholder,
	textarea::placeholder {
		color: transparent;
		font-family: $mainFont;	
	}
	
	// Set Placeholder Styles
	input::-webkit-input-placeholder,
	textarea::-webkit-input-placeholder {
		/* Chrome/Opera/Safari */
		color: transparent;
		font-family: $mainFont;	
	}

	input::-moz-placeholder,
	textarea::-moz-placeholder {
		/* Firefox 19+ */
		color: transparent;
		font-family: $mainFont;
	}

	input:-ms-input-placeholder,
	textarea:-ms-input-placeholder {
		/* IE 10+ */
		color: transparent!important;
		font-family: $mainFont!important;
	}

	input:-moz-placeholder,
	textarea:-moz-placeholder {
		/* Firefox 18- */
		color: transparent;
		font-family: $mainFont;	
	}

	.input {
		position: relative;
		display: block;
		width: 100%; 
		margin-bottom: 3rem;

		label {	
			position: absolute;
			display: block;
			top: 50%;
			left: rem($baseGap);
			transform: translate(0, -50%);
			color: $dark;
			z-index: 2;
			transition: 300ms all;

			small {
				color: $alert;
				display: inline-block;
				line-height: 0.5rem;
			}
		}

		[type="text"],
		[type="tel"],
		[type="email"],
		[type="file"],
		input,
		textarea,
		select {
			position: relative;
			display: block;
			width: 100%;
			height: 100%;
			padding: rem(30px) rem($baseGap); 
			border: none;
			background: $light;
			border: 2px solid rgba($secondary, 0.5);
			border-radius: 5px;
			transition: 300ms all;
			color: $dark;

			&:focus {
				background: rgba($secondary, 0.7);
				color: $light;
			}
		}

		&.text {
			margin-bottom: 0;
			margin-top: 1.5rem;

			label {
				top: rem(15px);
				transform: none;
			}
		}

		&.focused {
			label {
				color: $dark;
				top: 0;
				transform: translate(0, -50px);
			}

			::-webkit-input-placeholder {
				color: $light;
			}

			::-moz-placeholder {
				color: $light;
			}

			:-ms-input-placeholder {
				color: $light !important;
			}

			:-moz-placeholder {
				color: $light;
			}
		}
	}
}

//////////////////////////////// Fade Checkbox ////////////////////////////////

.fadeCheckbox {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;

    @include breakpoint(large) {
        max-width:640px;
        width: 100%;
    }

    label {
        width: calc(100% - 15px);
        position: relative;
        display: inline-block;
        padding-left: 0;
        order: 2;
        margin-bottom: 0;

        &.error {
            position: absolute;
            left: 0;
            top: 140px;

            @include breakpoint(large) {
                top: 60px;
            }
        }
    }

    label.custom-check {
        width: 17px;
        height: 16px;
        position: relative;
        display: inline-block;
        margin-right: 1.2rem;
        order:1;
        background: none;
        border: 1px solid $secondary;
        cursor: pointer;
        padding: 0; 
        min-height: 0;

        &:after {
        	content: '✓';
    		display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -100%);
            opacity: 0;
            transition: 300ms all;
        }
    }

    input {
        position:absolute; 
		opacity: 0;

        &:checked + label.custom-check {
            &:after {
            	transform: translate(-50%, -50%);
                opacity: 1;
            }
        }
        
        // Custom Field error styling
        &:required {
            &:not(:checked) {
                &.invalid {
                    +label {
                        border: 1px solid red;
                    }
                }
            }
        }
    }
}


.radios {
	display: flex;
	justify-content: space-around;

	.radioButton {
		margin-bottom: rem($baseGap);

		input[type="radio"]{
			opacity: 0;
			visibility: hidden;

			&:not(:checked) {
				+ label {
					&:after {
						visibility: hidden;
						opacity: 0;	
						transform: scale(0);
					}
				}
			}

			&:checked {
				+ label {
					&:after {
						opacity: 1;	
						visibility: visible;
						transform: scale(1);	
					}
				}
			}

			// Custom Field error styling
	    	&.invalid {
	        	+label {
	        		&:before {
	        			border: 1px solid red;
	        		}
	        	}
	    	}
		}

		[type="radio"] + label {
			display: flex;
			cursor: pointer;
			line-height: 1em;
			position: relative;
			align-items: center;
			margin-top: rem(7px);
		    margin-bottom: rem(7px);

			&:before {
				width: 18px;
				content: '';
				height: 18px;
				position: relative;
				margin-right: .5rem;
				border-radius: 100%;
				display: inline-block;
				border: 1px solid $dark;
			}

			&:after {
				left: 4px;
				width: 10px;
				content: '';
				height: 10px;
				position: absolute;
				margin-right: .5rem;
				border-radius: 100%;
				background: $primary;
				transition: 150ms all;
				display: inline-block;
				
				// IE11 needs an Extrawurst, top value needed
				body.internetExplorer & {
					top: 7px;
				}
			}
			
			body.internetExplorer & {
				padding: 0;
			}

		}
	}
}
