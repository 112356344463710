// SETTINGS

$break: 	giant; 	// break from mobile to desktop navigation
$barHeight: 50px; 	// height for quickbar
$barPos: 	top; // position (top, bottom)
$barHide: 	false; 	// if bar hides on scroll mobile (true, false)
$navHover:	true;	// hover functionality for desktop navigation
$time: 		0.3s; 	// transition for some stuff

// MOBILE NAVIGATION

@include breakpoint($break, max) {

	#navigation {
		transition:transform $time;
		background-color:$light;

		ul {
			background-color:$light;

			li {
				display: flex;
				width: 100%;

				a, span {
					align-items: center;
					border-bottom: rem(1px) solid rgba($dark,0.1);
					color:$dark;
					display: flex;
					font-size:rem(16px);
					justify-content: space-between;
					line-height: rem(24px);
					padding:rem(15px) rem($baseGap);
					text-decoration: none;
					width: 100%;
					transition:background $time, color $time;

					&.subToggler {

						&:after {
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
						};
					}
				};

				&.navBack {

					span {
						justify-content: flex-start;

						&:before {
							font-family: $iconFont;
							content: map-get($iconMap, angle-right);
							transform:rotate(180deg) translateY(rem(1px));
							margin-right: rem(7px);
						}
					}
				}

				.sub {

					&:before {
						color: $light;
						display: block;
						background:$medium;
						font-size: rem(19px);
						content:attr(data-parent-name);
						padding:rem(15px) rem($baseGap);
					}
				}

				// active status for all elements

				&.active > a, &.active > span, a.active, span.active {
					background:$primary;
					color:$light;
				}
			}
		}

		//////////////////////////////////////////////////////////////////////////////////////////// 
		//essential styles for an working mobile subnavigation
		// do not change this, unless you know what you do
		
		min-width:90%;
		max-width: 100%;
		overflow:hidden;
		position:fixed;
		right:0;
	 	z-index:1002;

	 	@if $barPos == "top" {
	 		bottom:0;
			top:rem($barHeight);
		} @else if $barPos == "bottom" {
			top: 0;
			bottom:rem($barHeight);
		}

	 	nav {
	 		overflow: hidden;
    		overflow-y: auto;
    		height:calc(100vh - #{rem($barHeight)});

    		ul {
    			padding-bottom: rem(100px); // fix for mobile phones with a toolbar at the end
    			width:100%;

    			&.sub {
					top:0;
					left:0;
					position:absolute;
					transform:translateX(100%);
					transition:transform $time;
					height:calc(100vh - #{rem($barHeight)});
				}

				&.current {
					transform:translateX(0%);

					&.open {
						z-index: 1;
						overflow:hidden;
						overflow-y:auto;
						-webkit-overflow-scrolling:touch;

						li.leistungen {
							display: none;
						}
					}
				}
    		}
	 	}

	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	 	//////////////////////////////////////////////////////////////////////////////////////////// 
	}

	html.hiddenScroll {
		overflow:hidden;
	}
}

// DESKTOP NAVIGATION

@include breakpoint($break) {

	#navigation {
		z-index: 100;
		position: relative;

		nav {

			.navi {
				display: flex;
				justify-content: space-between;

				// only first level <li>

				> li {

					> a, > span {
						padding:rem(7px) 0;

						&:before {
							left: 0;
							top:120%;
							opacity: 0;
							content:'';
							width: 100%;
							height: rem(2px);
							position: absolute;
							visibility: hidden;
							background:$secondary;
							transition:opacity $time, visibility $time, top $time;
						}
					}

					&.startseite {
						@include breakpoint(full, max) {
							display: none;
						}
					}

					&.stellenangebote {
						border-radius: rem(5px);
						padding: rem(5px) rem(10px);
						background-color: $secondary;
						border: rem(2px) solid $secondary;
						transition: background-color $animation-time ease-in-out, border-color $animation-time ease-in-out;

						@include breakpoint(giant) {
							&::before {
								color: $light;
								font-family: $iconFont;
								margin-right: rem(7px);
								content: map-get($iconMap, 'line-person-two');
							}							
						}


						&:hover,
						&:focus,
						&:active {
							border-color: $primary;
							background-color: $primary;
						}

						i {
							display: none;

							@include breakpoint(giant) {
								display: block;
							}
						}

						a {
							color: $light;

							&:before {
								display: none;
							}
						}
					}

					// active and hover status for all first level elements

					&.active > a, &.active > span, &:hover > a, &:hover > span,  > a.active, > span.active {
						
						&:before {
							top: 100%;
							opacity: 1;
							visibility: visible;
						}
					}

					@if $navHover {

				 		body.isDesktop & {

							&:hover {

								> .sub {
									top: 100%;
									opacity: 1;
									visibility: visible;
									pointer-events: auto;
								}
							}

							.navBack {
								display: none;
							}
						}

					};
				}

				li {
					display: flex;
					position: relative;
					align-items: center;

					&.impressum,
					&.datenschutzerklaerung,
					&.facebook {
						@include breakpoint($break) {
							display: none;
						}
					}

					&.leistungen {
						li.leistungen {
							display: none;
						}
					}

					a, span {
						width: 100%;
						display: flex;
						cursor: pointer;
						color:$secondary;
						font-weight: 600;
						line-height: 100%;
						position: relative;
						white-space: nowrap;
						align-items: center;
						font-size:rem(20px);
						text-decoration: none;

						&.subToggler {

							&:after {
								margin-left: rem(4px);
								font-family: $iconFont;
								content: map-get($iconMap, arrow-right);
								transform:rotate(90deg) translateX(2px);
							};
						}
					}

					&.navBack {
						text-align: center;

						span {
							display: none;
						}

						&:before {
							width: 100%;
							display: block;
							cursor: pointer;
							font-size: rem(16px);
							background:$secondary;
							font-family: $iconFont;
							transition:background $time;
							padding:rem(5px) rem($baseGap);
							content: map-get($iconMap, times);
						}

						&:hover {

							&:before {
								background:darken($primary,10%);
							}
						}
					}			

					.sub {
						left:0;
						top:120%;
						opacity: 0;
						min-width: 100%;
						background:$light;
						position: absolute;
						visibility: hidden;
						pointer-events: none;
						box-shadow:0 0 rem(10px) rgba($black,0.05);
						transition:opacity $time, visibility $time, top $time;

						li {
							a, span {
								padding:rem(15px) rem($baseGap);
								transition:background $time, col or $time;
								
							}

							// active and hover status for all sub a elements

							&.active > a, &.active > span, a.active, span.active, &:hover > a, &:hover > span, a:hover, span:hover {
								background:$primary;
								color:$light;
							}

							@if $navHover {

						 		body.isDesktop & {

									&:hover {

										> .sub {
											top:0%;
											opacity: 1;
											visibility: visible;
											pointer-events: auto;
										}
									}

									.navBack {
										display: none;
									}
								}

							};
						}

						&.current {
							top:100%;
							opacity: 1;
							visibility: visible;
							pointer-events: auto;
						}

						&.open {
							top:100%;
						}

						.sub {
							top:20%;
							left:100%;

							&.current, &.open {
								top:0;
							}
						}
					}
				}
			}
		}
	}
}

// MOBILE QUICKBAR

@include breakpoint($break, max) {

	#quickbar {
		box-shadow:0 0 rem(10px) rgba(black,0.1);
		height:rem($barHeight);
		background-color:$light;
		display:flex;
		justify-content:space-between;
		align-items:center;
		z-index:1000;
		position:fixed;
		left:0;
		width:100%;
		padding:0 rem($baseGap);
		transform:translateY(0%);
		transition:transform $time;

		@if $barPos == "top" {
			top: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrollingDown & {
					transform: translateY(-100%);
				}
			}
		} @else if $barPos == "bottom" {
			bottom: 0;
			@if $barHide {
				html:not(.hidden-scroll) body.scrollingDown & {
					transform: translateY(100%);
				}
			}
		}

		label[for="naviToggled"] {
			display:block;
			cursor:pointer;
			width:rem(20px);
			height:rem(20px);
			position:relative;
			overflow:hidden;

			&:after{
				content:"";
				position:fixed;
				left:0;
				width:200vw;
				height:200vh;
				display:block;
				background-color:rgba($black,0.4);
				z-index:-1;
				transition: opacity $time, visibility $time;

				@if $barPos == "top" {
					top:rem($barHeight);
				} @else if $barPos == "bottom" {
					bottom:rem($barHeight);
				}
			}

			span {
				transition:0.4s linear;
				text-indent:-9999px;
				width:0;
				top:50%;
				transform:translate(-50%, -50%);

				&:before, &:after {
					top:50%;
					width:rem(20px);
					transition:top $time ease-in-out, transform $time 0.2s linear;
				}

				&:before {
					transform:translate(-50%, -50%) rotate(45deg);
				};

				&:after {
					transform:translate(-50%, -50%) rotate(-45deg)
				};
			}

			span, span:before, span:after {
				content:"";
				position:absolute;
				display:block;
				background:$dark;
				height:2px;
				left:50%;
				transform-origin:center
			};
		};
	};
	
	#naviToggled {

		&:not(:checked) {

	 		& ~ * #navigation,
	 		& ~ #navigation {
				transform:translateX(100%);
	 		};

	 		& ~ #quickbar {
				
				label[for="naviToggled"] {

					span {
						width:20px;

						&:before, &:after {
							transform:translate(-50%, -50%);
							transition:top $time 0.2s ease-in-out, transform $time linear;
						};

						&:before {
							top:calc(50% - 5px);
						};

						&:after {
							top:calc(50% + 5px);
						};
					}

					&:after {
						opacity:0;
		 				visibility:hidden
					};
				}

	 		};
	 		
	 	};
	}
}

#naviToggled {
	display:none;
};

@include breakpoint($break) {
	#quickbar {
		display: none;
	}
}