// PAGEWRAP
//////////////////////////////
#pageWrap {
	padding-top: $barHeight;

	@include breakpoint(giant) {
		padding-top: rem(159px + $baseLineHeight);
	}

	body:not(.index) & {
		@include breakpoint(giant) {
			padding-top: rem(135px);			
		}
	}


	// NAVIGATION
	//////////////////////////////


	.fixedContainer {
		width: 100%;
		z-index: 100;
		display: flex;
		align-items: flex-end;
		background-color: $light;
		padding: rem($baseGap) 0;
		transition: box-shadow $animation-time ease-in-out, padding $animation-time ease-in-out;

		@include breakpoint(tiny) {
			padding-bottom: 0;
		}

		@include breakpoint(giant) {
			top: 0;
			left: 0;
			position: fixed;
			padding: rem(60px) 0 0 0;
		}

		body.cmsBackend & {
			display: none;
		}

		body.scroll & {
			@include breakpoint($break) {
				box-shadow: 0 rem(-29px) rem(110px) 0 rgba($dark,0.6);
			}
		}

		body.scroll &,
		body:not(.index) & {
			@include breakpoint($break) {
				padding: rem($baseGap) 0;
			}

			.branding {
				img {
					@include breakpoint($break) {
						max-width: rem(270px);
					}
				}
			}
		}

		.branding {
			//display: none;
			display: table;
			margin-left: auto;
			margin-right: auto;
			max-height: 100%;
			position: relative;
			margin-bottom: rem($baseLineHeight);

			img {
				width: 100%;
				height: auto;
				max-width: rem(369px);
				transition: max-width $animation-time ease-in-out;			
				image-rendering: -webkit-optimize-contrast;
			}

			@include breakpoint(giant) {
				margin-bottom: 0;
			}
		}


		// Info toggler
		//////////////////////////////

		input[id^="infoToggled"] {
			display: none;

			@include breakpoint(giant, max) {
				&:not(:checked) {
			 
					+ {
						.infoContainer {
							width: 0;
							height: 0;
							opacity: 0;
							z-index: -1000000;
							position: relative;
							visibility: hidden;
							transform:scale(0);
							pointer-events: none;
						}
					}
				}		
			} 
		}

		.positionContainer {
			@include breakpoint(giant, max) {
				display: flex;
				justify-content: center;		
			}

			label[for="infoToggled"] {
				&:nth-child(1) {
					width: 100%;
					color: $light;
					display: flex;
					line-height: 100%;
					box-shadow: $shadow;
					align-items: center;
					max-width: rem(200px);
					border-radius: rem(50px);
					background-color: $primary;
					justify-content: space-between;
					padding: rem($baseGap/2) rem($baseGap);

					a, span, i {
						color: $light;
					}
					
					@include breakpoint(giant) {
						display: none;
					}
				}
			}	
		}

		.infoContainer {

			@include breakpoint(giant, max) {

				body:not(.cmsBackend) & {
					top: 0;
					left: 0;
					opacity: 1;
					width: 100%;
					display: flex;
					height: 100vh;
					position: fixed;
					overflow: hidden;
					z-index: 1000000;
					visibility: visible;
					align-items: center;
					justify-content: center;
					transition: opacity $animation-time ease-in-out, visibility $animation-time ease-in-out;
			 
					ul {
			            width: 100%;
						max-height: 90vh;
				    	overflow: hidden;
				        overflow-y: auto;
						background: $light;
						box-shadow: $shadow;
						padding: rem($baseGap);
				        border-radius: rem(5px);
						padding-left: calc(#{rem(-$baseGap*3)});
						max-width: calc(100% - #{rem(110px)}); // for the close label
				 
						@include breakpoint(giant) {
							max-width: 80%;
						}
				 
						@include breakpoint(full) {
							max-width: 70%;
						}
					}
				}

				label {
					display:none;
				
					body:not(.cmsBackend) & {
						top: 0;
						right: 0;
						width: 100%;
						z-index: -1;
						height: 100%;
						display: flex;
						position: absolute;
						padding: rem($baseGap);
						justify-content: flex-end;
						background: rgba($secondary, 0.8);
				 
						i {
							display: block;
							cursor: pointer;
							width: rem(35px);
							height: rem(35px);
							position: relative;
							transition: background .3s;
				 
							&:hover {
								background: rgba($secondary, 0.8);
				 
								&:before, &:after {
									background: white;
								};
							}
				 
							&:before, &:after {
								top: 50%;
								left: 50%;
								content: '';
								display: block;
								width: rem(30px);
								height: rem(2px);
								position: absolute;
								background: $light;
							}
				 
							&:before {
								transform: translate(-50%,-50%) rotate(45deg);
							}
				 
							&:after {
								transform: translate(-50%,-50%) rotate(-45deg);
							}
						}
					}
				}
			}
			
			ul {
				display: flex;
				flex-flow: column wrap;

				@include breakpoint(giant) {
					justify-content: flex-end;
					flex-flow: row nowrap;
				}

				li {
					color: $secondary;
					font-size: rem(15px);
					margin-left: rem($baseGap*2);
					margin-bottom: rem($baseGap);

					@include breakpoint(giant) {
						font-size: rem(16px);
					}

					@include breakpoint(huge) {
						font-size: rem(18px);
					}

					&:hover a,
					&:focus a,
					&:active a {
						opacity: 0.5;
					}


					i {
						transform: translate(0, 0);
						margin-right: rem($baseGap);

						@include breakpoint(giant) {
							margin-right: rem(5px);
						}
					}

					&:nth-child(4) {
						a {
							@include breakpoint(giant) {
								font-size: 0;
							}						
						}
					}

					a {
						color: $secondary;
						transition: opacity $animation-time ease-in-out;
					}
				}
			}
		}
	}

	// HEADER
	//////////////////////////////

	#header {
		height: 76vw;
		position: relative;
		margin-top: rem($baseGap);
		margin-bottom: rem($baseGap);

		@include breakpoint(tiny) {
			body:not(.internetExplorer) & {
				height: 100%;
			}
		}

		@include breakpoint(small) {
			body.internetExplorer & {
				height: 100%;
			}			
		}

		@include breakpoint(giant) {
			margin-bottom: rem(100px);
		}
		
		@include breakpoint(huge) {
		}

		body.cmsBackend & {
			display: none;
		}

		body:not(.index) & {
			margin-top: 0;
			height: 50vw;
			max-height: 200px;

			@include breakpoint(small) {
				margin-bottom: rem($baseGap*2);
			}

			&:after {
				top: rem(40px);
				height: 36vw;
				max-height: rem(150px);

				@include breakpoint(tiny) {
					bottom: rem(30px);
					height: rem(150px);
				}

				@include breakpoint(small) {
					bottom: rem(-$baseGap);
				}

				@include breakpoint(full) {
					bottom: rem(30px);
					height: rem(120px);
				}
			}

			.swiper-container {
				display: none;
			}

			.row {
				justify-content: center;
				position: relative;

				> .col.large-3 {
					display: none;
				}

				.page {
					display: flex;
					justify-content: center;

					span {
						left: 0;
						width: 100%;
						color: $light;
						position: absolute;
						text-align: center;
						bottom: rem($baseGap*3.5);

						@include breakpoint(tiny) {
							bottom: rem($baseGap);
						}

						@include breakpoint(small) {
							bottom: rem(-$baseGap*1.5);
						}

						@include breakpoint(full) {
							bottom: rem($baseGap*1.5);
						}
					}
				}
			}		

			.claim {
				width: 100%;
				max-height: rem($baseGap*5);

				&:before {
					height: 100%;
					opacity: 1;
				}

				.buttonWrapper {
					display: none;

					@include breakpoint(full) {
						display: block;
						margin-top: rem($baseGap);
					}
				}
			}

			.positionWrapper {
				bottom: 0;

				@include breakpoint(tiny) {
					bottom: rem(-150px);
				}

				@include breakpoint(small) {
					bottom: 0;
				}

				span {
					@include breakpoint(small, max) {
						margin-bottom: rem(250px);
					}
				}
			}
		}

		&:after {
			left: 0;
			content: '';
			width: 100%;
			z-index: -1;
			height: rem(350px);
			position: absolute;
			bottom: rem(-46px);
			background-color: $primary;

			@include breakpoint(giant) {
				bottom: 0;
			}
		}

		.row {
			padding-bottom: rem(140px);

			@include breakpoint (tiny) {
				padding-bottom: rem(100px);
			}
			
			@include breakpoint(small) {
				padding-bottom: rem($baseGap*3);
			}

			@include breakpoint(full) {
				padding-bottom: rem(101px);
			}
		}

		.swiper-container {
			top: 0;
			height: 100%;
			position: absolute;
			left: rem($baseGap);
			box-shadow: $shadow;
			width: calc(100% - #{rem($baseGap*2)});

			.swiper-wrapper {
				.swiper-slide {
					cursor: grab;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: top center;
					
					&.slide01 {
						background-image: image-url("swiper/giant-swiperImage1.jpg");

						@include breakpoint(giant) {
							background-image: image-url("swiper/huge-swiperImage1.jpg");
						}

						@include breakpoint(huge) {
							background-image: image-url("swiper/swiperImage1.jpg");	
						}
					}

					&.slide02 {
						background-image: image-url("swiper/giant-swiperImage2.jpg");

						@include breakpoint(giant) {
							background-image: image-url("swiper/huge-swiperImage2.jpg");
						}

						@include breakpoint(huge) {
							background-image: image-url("swiper/swiperImage2.jpg");	
						}
					}

					&.slide03 {
						background-image: image-url("swiper/giant-swiperImage3.jpg");

						@include breakpoint(giant) {
							background-image: image-url("swiper/huge-swiperImage3.jpg");
						}

						@include breakpoint(huge) {
							background-image: image-url("swiper/swiperImage3.jpg");	
						}
					}
				}			
			}
		}

		.claim {
			display: flex;
			position: relative;
			box-shadow: $shadow;

			body.internetExplorer11 & {
				@include breakpoint(small, max) {
					max-height: none;
				}

				@include breakpoint(tiny) {
					max-height: 500px;
				}

				@include breakpoint(medium) {
					overflow: hidden;
					max-height: rem(500px);
				}

				@include breakpoint(full) {
					max-height: rem(607px);
				}
			}

			body.internetExplorer11:not(.index) & {
				overflow: hidden;
				max-height: rem(90px);
			}

			@include breakpoint(small, max) {
				max-height: 70%;
			}

			@include breakpoint(medium) {
				max-height: rem(500px);
			}

			@include breakpoint(full) {
				max-height: rem(607px);
			}

			&:before {
				left: 0;
				bottom: 0;
				z-index: 1;
				width: 100%;
				height: 40%;
				content: '';
				opacity: 0.7;
				position: absolute;
				background: $black;
	            background: linear-gradient(0deg, rgba($black, 1) 0%, rgba($light, 0) 100%);

	            @include breakpoint(small) {
	            	height: 90%;
	            }

	            @include breakpoint(giant) {
	            	height: 60%;
	            }
			}


			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: top center;
			}

			.positionWrapper {
				left: 0;
				z-index: 2;
				width: 100%;
				color: $light;
				display: flex;
				flex-wrap: nowrap;
				position: absolute;
				text-align: center;
				align-items: center;
				flex-direction: column;
				top: calc(100% - #{rem($baseGap*2)});

				@include breakpoint(tiny) {
					font-size: rem(24px);
					justify-content: center;
					top: calc(50% + #{rem($baseGap*2.5)});
				}

				@include breakpoint(small) {
					bottom: 0;
					text-align: left;
					left: rem($baseGap);
					align-items: flex-start;
					justify-content: flex-start;
					flex-direction: column-reverse;
				}

				@include breakpoint(full) {
					flex-direction: row;
					align-items: flex-end;
					left: rem($baseGap*2);
					bottom: rem($baseGap);
				}

				span {
					font-size: 5.5vw;
					margin-bottom: rem(30px);

					@include breakpoint(large) {
						font-size: 4vw;
					}

					@include breakpoint(full) {
						font-size: rem(34px);
						margin-right: rem($baseGap);
					}
				}

				.buttonWrapper {
					display: flex;
					flex-wrap: wrap;
					flex-direction: column;
					justify-content: center;

					@include breakpoint(tiny) {
						margin-top: rem($baseGap);						
					}

					@include breakpoint(small) {
						flex-direction: row;
						margin-bottom: rem($baseGap);
					}

					@include breakpoint(huge) {
						margin-bottom: rem($baseGap*2);
					}

					@include breakpoint(full) {
						margin-bottom: rem($baseGap);
					}

					.btn {
						&:nth-child(1),
						&:nth-child(2) {
							color: $light;
							border-color: $light;
							background-color: transparent;
							padding: rem($baseGap*0.7) rem($baseGap*1.5);

							@include breakpoint(full) {
								margin-bottom: 0;
							}

							&:hover,
							&:focus,
							&:active {
								border-color: $primary;
								background-color: $primary;
							}
						}

						&:nth-child(1) {
							@include breakpoint(small, max) {
								margin-bottom: rem($baseGap);
							}
						}


						@include breakpoint(large) {
							line-height: 100%;

							
							&:nth-child(2) {
								color: $light;
								border-color: $light;

								&:hover,
								&:focus,
								&:active {
									border-color: $primary;
									background-color: $primary;
								}
							}
						}

						@include breakpoint(small) {
							&:nth-child(1) {
								margin-right: rem($baseGap);
							}						
						}

						@include breakpoint(full) {
							&:nth-child(1) {
								margin-left: rem($baseGap*2);
							}
						}
					}
				}
			}
		}
	}

	// MAIN CONTENT
	//////////////////////////////

	main {
		display: block;

		p,
		ol,
		ul,
		dl,
		table {
			@extend .marginBottom;
		}

		ul:not(.unstyledList),
		dl:not(.unstyledList),
		ol:not(.unstyledList) {
			@extend .styledList;
		}

		#privacyPolicy
		{
			a {
				display: inline-block; 
				word-break: break-all;
			}
		}

		&#content {
			margin-bottom: rem(100px);

			body:not(.index) & .row {
				@include breakpoint(large) {
					position: relative;
					border-radius: rem(5px);
					padding: rem($baseGap) 0;

					&:before {
						top: 0;
						left: 0;
						content: '';
						height: 100%;
						width: rem(5px);
						position: absolute;
						border-radius: rem(10px);
						background-color: $primary;
					}

					.row,
					&.noLine {
						padding: 0;

						&:before {
							display: none;
						}
					}
				}
			}
		}

		.box {
			position: relative;

			&::after {
				left: 50%;
				width: 90%;
				z-index: -1;
				content: '';
				display: none;
				top: rem(-10px);
				position: absolute;
				box-shadow: $shadow;
				border-radius: rem(5px);
				background-color: $light;
				transform: translateX(-50%);
				height: calc(100% + #{rem($baseGap)});

				@include breakpoint(medium) {
					display: block;
				}
			}
		}

		.teaserWrapper {
			display: flex;
			height: rem(250px);
			justify-content: space-between;
			
			@include breakpoint(giant, max) {
				overflow-x: scroll;
			}

			.teaser {
				display: block;
				flex-shrink: 0;
				width: rem(220px);
				height: rem(200px);
				position: relative;
				box-shadow: $shadow;
				flex-basis: rem(220px);
				background-size: cover;
				border-radius: rem(4px);
				background-color: $light;
				margin-right: rem($baseGap);
				background-repeat: no-repeat;
				background-position: top center;

				@include breakpoint(tiny) {
					width: rem(270px);
					flex-basis: rem(270px);
				}

				@include breakpoint(giant) {
					width: calc(20% - #{rem(27px)});
					flex-basis: calc(20% - #{rem(27px)});
				}

				&.lazyLoaded {
					&:nth-child(1) {
						background-image: image-url("layout/buero.jpg");
					}

					&:nth-child(2) {
						background-position: bottom;
						background-image: image-url("layout/fensterputzer.jpg");
					}

					&:nth-child(3) {
						background-image: image-url("layout/photovoltaik.jpg");
					}

					&:nth-child(4) {
						background-image: image-url("layout/rolladen.jpg");
					}

					&:nth-child(5) {
						margin-right: 0;
						background-image: image-url("layout/bauendreinigung.jpg");
					}
				}


				span {
					color: $dark;
					bottom: rem(-40px);
					position: absolute;
				}

				svg {
					left: 50%;
					bottom: 0;
					opacity: 0;
					z-index: 2;
					position: absolute;
					transform: translateX(-50%);
					transition: opacity $animation-time ease-in-out, bottom $animation-time ease-in-out, transform $animation-time ease-in-out; 
				}

				i {
					transform: translate(0, 0);
					transition: padding-left $animation-time ease-in-out;
				}

				&:before {
					left: 0;
					bottom: 0;
					z-index: 1;
					opacity: 0;
					width: 100%;
					content: '';
					height: 100%;
					position: absolute;
					background: $black;
		            transition: opacity 366ms ease-in-out;
		            background: linear-gradient(0deg, rgba($black, 1) 0%, rgba($light, 0) 100%);
				}

				&:hover,
				&:focus,
				&:active {
					& svg {
						opacity: 1;
						bottom: 30%;
					}

					&:before {
						opacity: 0.6;
					}

					& i {
						padding-left: rem(10px);
					}
				}
			}
		}

		.comparisonWrapper {
			position: relative;

			&:before {
				left: 0;
				content: '';
				width: 100%;
				z-index: -1;
				position: absolute;
				bottom: rem($baseGap*4);
				background-color: $primary;
				height: calc(100% - #{rem($baseGap*8)});

				@include breakpoint(giant) {
					height: rem(200px);
					bottom: rem(100px);
				}
			}

			.compare,
			.allInOne {
				position: relative;
				box-shadow: $shadow;
				overflow: hidden;

				&:before {
					left: 0;
					bottom: 0;
					z-index: 1;
					width: 100%;
					height: 24%;
					content: '';
					position: absolute;
					pointer-events: none;
					background: rgb(0,0,0);
		            background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 100%);
				}

				span, 
				svg {
					pointer-events: none;
				}

				img {
		    		object-fit: cover;
					height: rem(200px);

		    		@include breakpoint(medium) {
		    			height: rem(300px);
		    		}

		    		@include breakpoint(giant) {
		    			height: rem(350px);
		    		}

		    		@include breakpoint(huge) {
		    			height: rem(400px);
		    		}

		    		@include breakpoint(full) {
		    			height: rem(450px);
		    		}

		    		@include breakpoint(fuller) {
		    			height: rem(500px);
		    		}
				}
			}

			.compare {
				height: 100%;
				margin-top: rem($baseGap*2);

				span {
					font-size: rem(18px);
				}

				@include breakpoint(small) {
					margin-top: 0;
				}
			}

			.allInOne {

				span {
					font-size: rem(20px);
				}

				&:before {
					height: 70%;

					@include breakpoint(giant) {
						height: 40%;				
					}

					@include breakpoint(full) {
						height: 20%;				
					}
				}
			}

			svg {
				left: 0;
				z-index: 2;
				display: none;
				bottom: rem(32px);
				position: absolute;
				left: rem($baseGap*2);
				transition: transform 366ms ease-in-out;

				@include breakpoint(giant) {
					display: block;
				}
			}

			span {
				z-index: 2;
				color: $light;
				position: absolute;
				left: rem($baseGap);
				bottom: rem($baseGap);
				transition: transform $animation-time ease-in-out;

				@include breakpoint(huge) {
					left: rem($baseGap*2);
					bottom: rem($baseGap*2);
				}

				&:nth-child(3) {
					left: rem($baseGap);

					@include breakpoint(giant) {
						left: rem($baseGap*6);
					}
				}

				> span {
					display: block;
					position: static;
					font-weight: 300;
				}
			}
		}

		.cta {
			display: flex;
			margin: rem(-6px);
			position: relative;
			text-align: center;
			box-shadow: $shadow;
			flex-flow: column wrap;
			justify-content: center;
			border-radius: rem(7px);
			padding: rem($baseLineHeight*2) rem($baseGap);

			@include breakpoint(large) {
				padding: rem(150px) 0;
			}

			@include breakpoint(giant) {
				margin-bottom: rem(100px);		
			}

			&.lazyLoaded {
				&:before {
					top: 0;
					left: 0;
					content: '';
					width: 100%;
					z-index: -2;
					height: 100%;
					position: absolute;
					background-size: cover;
					border-radius: rem(7px);
					background-position: center;
					background-repeat: no-repeat;
					background-image: image-url("layout/old-window.jpg");	
				}
			}


			&:after {
				top: 0;
				left: 0;
				width: 100%;
				z-index: -1;
				content: '';
				height: 100%;
				position: absolute;
				border-radius: rem(7px);
				background-color: rgba($secondary, 0.68);
			}
			
			.buttons {
				display: flex;
				align-items: center;
				flex-flow: column wrap;
				justify-content: center;

				@include breakpoint(tiny) {
					flex-flow: row nowrap;
				}

				.btn {
					color: $light;
					border-color: $light;
					padding: rem($baseGap/2) rem($baseGap);

					@include breakpoint(small) {
						padding: rem($baseGap/2) rem($baseGap*1.5);
					}

					&:nth-child(1) {
						margin-bottom: rem($baseGap);

						@include breakpoint(tiny) {
							margin-bottom: 0;
							margin-right: rem($baseGap);
						}

						@include breakpoint(small) {
							margin-right: rem($baseGap*2);
						}
					}

					&:hover,
					&:active,
					&:focus {
						border-color: $secondary;
					}
				}
			}

			span {
				color: $light;
				display: block;

				&:nth-child(1) {
					font-weight: 600;
					font-size: rem(28px);
					margin-bottom: rem($baseGap);
					line-height: rem($baseLineHeight*1.5);

					@include breakpoint(small) {
						font-size: rem(38px);
					}

					@include breakpoint(large) {
						font-size: rem(48px);
						margin-bottom: rem($baseGap*1.5);
					}
				}

				&:nth-child(2) {
					margin-bottom: rem($baseLineHeight*1.5);
				}
			}
		}

		.subNavigation {
			display: flex;
			justify-content: center;

			li {
				display: flex;
				text-align: center;
				margin-bottom: rem($baseGap)*2;


				a {
					color: $dark;
					display: block;
					padding:rem(30px);
					box-shadow: $shadow;
					background: $light;
					text-decoration: none;

					span {
						display: block;
						margin: rem($baseGap) 0;
						color: $dark;

						&:not(.readMore) {
							margin-bottom: rem(10px);
						}
					}
					
					&:hover .btn {
						background-color: darken($secondary, 10%);
						color: $light;
					}
				}
			}
		}
	}

	// FOOTER
	//////////////////////////////

	#footer {
		height: 100%;
		position: relative;
		margin-top: rem($baseGap);
		padding-bottom: rem($baseGap);

		@include breakpoint(giant) {
			margin-bottom: rem($baseGap);
		}

		@include breakpoint(huge) {
			margin-bottom: rem(150px);
		}

		body.cmsBackend & {
			display: none;
		}

		&:before {
			left: 0;
			content: '';
			width: 100%;
			position: absolute;
			top: rem(-$baseGap);
			background-color: $primary;
			height: calc(#{rem($baseGap)} + 100%);

			@include breakpoint(giant) {
				top: rem(-62px);
				height: rem(200px);
			}
		}

		.maps,
		.contact {
			box-shadow: $shadow;
		}

		.maps {
			height: rem(300px);

			@include breakpoint(small) {
				height: 100%;
			}

			iframe {
				width: 100%;
				height: 100%;
			}
		}

		.contact {
			position: relative;
			font-size: rem(15px);
			padding: rem($baseGap);
			background-color: $light;

			@include breakpoint(medium, max) {
				margin-bottom: rem($baseGap);
			}

			@include breakpoint(small) {
				margin-bottom: 0;
			}

			@include breakpoint(medium) {
				line-height: rem(23px);
				padding: rem($baseGap*2);
			}

			@include breakpoint(full, max) {
				padding: rem($baseGap);
			}

			a, span, address {
				display: block;
				color: $secondary;
			}

			a, span {
				margin-bottom: rem($baseLineHeight/2);

			}

			a {
				&:hover,
				&:focus,
				&:active {
					color: $primary;
				}
			}

			address {

				@include breakpoint(giant) {
					margin-bottom: rem($baseLineHeight*1.5);
				}
			}

			.infoList {

				li {
					position: relative;
					padding-left: rem(30px);

					@include breakpoint(medium) {
						padding-left: rem(30px);
					}

					@include breakpoint(giant) {
						
					}

					i {
						left: 0;
						top: rem(3px);
						position: absolute;

						&:nth-child(1) {
							transform: translate(0, 0);
						}
					}
				}
			}

			.naviAdd {			

				@include breakpoint(giant, max) {
					display: none;
				}

				@include breakpoint(giant) {
					padding-left: rem(30px);
				}

				.active {
					a {
						color: $primary;
					}
				}

				a {
					margin-bottom: rem(8px);
				}
			}
		}
	}
}
