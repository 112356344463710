/* This file was automaticaly generated by the DFS worker. Changes here will be overwritten. 
 To do change edit the config.json file! */ 

$imagePath: "../images/" !default;

$baseFontSize: 18px;

$baseLineHeight: 24px;

$h1Size: 32px;

$h2Size: 28px;

$h3Size: 24px;

$h4Size: 22px;

$h5Size: 18px;

$h6Size: 16px;

$mainFont: Barlow, sans-serif;

$displayFont: Barlow, sans-serif;

$iconFont: icomoon;

// Project colors

$primary: #9cc233;
$secondary: #114261;
$light: #fff;
$medium: #9cc233;
$dark: #615c5c;
$border: #b7da55;
$alert: #114261;
$black: #000;

$shadow: rem(11px) rem(7px) rem(20px) rem(-10px) rgba($dark, 0.6);

$animation-time: 200ms;

$baseGap: 18px;

$rowMaxWidth: 1620px;

$columnCount: 12;

$baseBorder: 1px solid $border;

$outline: 1px dotted $alert;

$bpContext: 16px;

$hideTextDirection: right;

// Project iconMap

$iconMap: (
	search: "\f002", 
	gesture-phone: "\e9e2", 
	arrow-down: "\ea1d", 
	arrow-right: "\ea1f", 
	angle-up: "\ea21", 
	angle-right: "\ea2a", 
	map-marker: "\ea3d", 
	pencil: "\ea7a", 
	phone: "\ea81", 
	exclamation: "\e90d", 
	minus: "\e90c", 
	plus: "\e90a", 
	times: "\e909", 
	circles: "\e965", 
	line-person-two: "\ea28", 
);

// Project gridMap

$gridMap: (
	default: (
		width: em(0px, $bpContext), 
		bottom: (align-items:flex-end),
		center: (justify-content:center),
		cols: (6),
		first: (order:-1),
		last: (order:1),
		middle: (align-items:center),
	),
	tiny: (
		width: em(480px, $bpContext), 
		cols: (6, 8),
	),
	small: (
		width: em(640px, $bpContext), 
		cols: (3, 6),
		first: (order:-1),
		last: (order:1),
	),
	medium: (
		width: em(760px, $bpContext), 
		cols: (3, 4, 5, 6, 7, 8, 10),
		prefix: (1),
	),
	large: (
		width: em(992px, $bpContext), 
		cols: (3, 4, 5, 6, 7, 9),
		prefix: (1),
	),
	giant: (
		width: em(1200px, $bpContext), 
		cols: (2, 3, 4, 5, 6, 8, 9, 10),
		prefix: (1),
		suffix: (1),
	),
	huge: (
		width: em(1364px, $bpContext), 
		cols: (3, 5, 7, 9, 10),
	),
	full: (
		width: em(1520px, $bpContext), 
		cols: (2),
	),
	fuller: (
		width: em(1620px, $bpContext), 
	),
	cut: (
		width: em($rowMaxWidth, $bpContext), 
		cols: (auto),
	),
);

