/* default lightbox hover */
[data-rel^="lightbox"], 
a.lightboxImage {
	width: 100%;
	display: table;
	position: relative;
	box-shadow: $shadow;
	text-decoration: none;

	&[href*="qr"] {
		width: auto;

		&:before,
		&:after {
			content: none;
		}
	}

	&:before,
	&:after {
		pointer-events: none;
		transition: all 366ms ease-in-out;
	}

	&:before {
		top: 40%;
		right: 50%;
		z-index: 1;
		opacity: 0;
		color: $light;
		display: block;
		line-height: 2;
		width: rem(40px);
		height: rem(40px);
		position: absolute;
		text-align: center;
		border-radius: 100%;
		font-size: rem(20px);
		@extend .icon-search;
		font-family: $iconFont;
		transform: translateX(50%);
		background-color: $primary;
		text-shadow: rem(1px) rem(1px) rem(1px) rgba($dark, 0.8);
	}

	&:after {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 0;
		content: "";
		display: block;
		position: absolute;
		background-color: $secondary;
	}

	&:hover,
	&:focus {
		&:before {
			top: 50%;
			right: 50%;
			opacity: 1;
			transform: translate(50%,-50%);
		}

		&:after {
			opacity: 0.2;
		}

		& span {
			bottom: rem(200px);
		}
	}
}