@include fontFaceV2(
    $fontName: "icomoon",
    $fileName: "icomoon",
    $types: ("woff2","woff"),
    $style: normal
);

@include fontFaceV2(
    $fontName: "Barlow",
    $fileName: "barlow-v4-latin",
    $weights: ("300", "300|italic", "600"),
    $types: ("woff2","woff")
);